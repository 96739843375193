import React from "react"

import logo from "@images/opacityLogo.svg"
import mailIcon from "@images/mailIcon.svg"

import { Link } from "gatsby"

import { Row, Col, Collapse } from "antd"

const { Panel } = Collapse
import { UpOutlined } from "@ant-design/icons"

import * as styles from "./index.module.scss"

const FooterData = [
  //   {
  //     listname: "技术产品",
  //     sonItems: [
  //       { name: "SaaS独立站平台", to: "/solution/#top" },
  //       { name: "智能运营工具", to: "/solution/#middle" },
  //       { name: "供应链集成", to: "/solution/#bottom" },
  //     ],
  //   },
  {
    listname: "推广服务",
    sonItems: [
      { name: "Facebook推广服务", to: "/advertising/#facebook" },
      { name: "Google推广服务", to: "/advertising/#google" },
      { name: "Bing推广服务", to: "/advertising/#bing" },
      { name: "服务内容", to: "/advertising/#our" },
    ],
  },
  {
    listname: "营销力量",
    sonItems: [
      { name: "Facebook专营团队", to: "/marketing-force/#facebook" },
      { name: "Google/Bing等渠道", to: "/marketing-force/#google" },
      { name: "百度/头条/腾讯等渠道", to: "/marketing-force/#baidu" },
    ],
  },
  {
    listname: "政策协议",
    sonItems: [
      { name: "隐私政策", to: "/privacy-policy" },
      { name: "关于我们", to: "/aboutus" },
    ],
  },
]

export default function Footer() {
  return (
    <div className={styles.footer}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          {/* pc */}
          <div className={styles.footer_main}>
            {/* 第一个有点不一样所以要单独渲染 */}
            <div className={styles.first}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              <p>您的跨境电商全链路营销解决方案提供商</p>
              <div className={styles.mail}>
                {/* <div className={styles.left}>
                  <img src={mailIcon} alt="mailIcon" />
                </div> */}
                {/* <div className={styles.right}>
                  咨询邮箱
                  <br />
                  <b>bd@Zero Miracle.com</b>
                </div> */}
              </div>
            </div>
            {FooterData.map(item => {
              return (
                <div key={item.listname} className={styles.list}>
                  <div className={styles.title}>{item.listname}</div>
                  <ul>
                    {item.sonItems.map(item => (
                      <li key={item.name}>
                        <Link to={item.to} style={{ color: "#9396A3" }} onClick={()=>{
                          sessionStorage.clear()
                        }}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            })}
          </div>
          <div className={styles.line}></div>
          <div className={styles.copyright}>Copyright ©2021 Zero Miracle.</div>
        </Col>
        <Col md={0} sm={24} xs={24}>
          {/* mobile */}
          <div className={styles.mobile}>
            <div className={styles.first} style={{ padding: "30px 20px" }}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
              <p>您的跨境电商全链路营销解决方案提供商</p>
              <div className={styles.mail}>
                {/* <div className={styles.left}>
                  <img src={mailIcon} alt="mailIcon" />
                </div> */}
                {/* <div className={styles.right}>
                  咨询邮箱
                  <br />
                  <b>bd@Zero Miracle.com</b>
                </div> */}
              </div>
            </div>
          </div>
          <Collapse
            ghost
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <UpOutlined
                rotate={isActive ? 0 : 180}
                style={{ color: "#9396A3" }}
              />
            )}
          >
            {FooterData.map((item, index) => (
              <Panel
                key={index}
                header={
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "20px",
                      color: "#fff",
                    }}
                  >
                    {item.listname}
                  </span>
                }
              >
                {item.sonItems.map(item => (
                  <Link
                    to={item.to}
                    key={item.name}
                    onClick={() => {
                      sessionStorage.clear()
                    }}
                    style={{
                      color: "#9396A3",
                      display: "block",
                      height: "34px",
                      lineHeight: "34px",
                    }}
                  >
                    {item.name}
                  </Link>
                ))}
              </Panel>
            ))}
          </Collapse>

          <div className={styles.line} style={{ marginTop: "20px" }}></div>
          <div className={styles.copyright}>Copyright ©2021 Zero Miracle.</div>
        </Col>
      </Row>
    </div>
  )
}
