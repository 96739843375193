import React, { useEffect, useState } from "react"

import { Menu, Row, Col, Modal } from "antd"

import { Link } from "gatsby"

import Logo from "@images/Logo.svg"
import MenuIcon from "@images/menu.svg"
import CancelIcon from "@images/cancel.svg"

import * as styles from "./index.module.scss"

import "antd/dist/antd.css"

const LinkData = [
  {
    to: "/",
    name: "首页",
  },
//   {
//     to: "/solution",
//     name: "技术产品",
//   },
  {
    to: "/advertising",
    name: "推广服务",
  },
  {
    to: "/marketing-force",
    name: "营销力量",
  },
  {
    to: "/aboutus",
    name: "关于我们",
  },
]

export default function Header() {
  const [current, setCurrent] = useState("/")
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    let currentPathname = window.location.pathname
    setCurrent(currentPathname)
  })
  return (
    <div className={styles.box_shadow}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className={styles.menu}>
              <Menu mode="horizontal" selectedKeys={[current]}>
                {LinkData.map(item => (
                  <Menu.Item key={item.to}>
                    <Link to={item.to}>{item.name}</Link>
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          </div>
        </Col>
        <Col md={0} sm={24} xs={24}>
          <div className={styles.mobile_header}>
            <Link to="/">
              <img src={Logo} alt="logo" height="30px" width="150px" />
            </Link>
            <img
              src={MenuIcon}
              alt="menu"
              height="18px"
              width="18px"
              onTouchStart={() => {
                setVisible(true)
              }}
            />
          </div>
          <Modal
            visible={visible}
            footer={null}
            closable={false}
            mask={false}
            // getContainer={false}
            style={{ maxWidth: "100%", margin: "0", top: "50px" }}
            bodyStyle={{ padding: "0", height: "100vh" }}
            zIndex={100}
            wrapClassName={styles.wrap}
          >
            <div className={styles.expand_content}>
              <div className={styles.cancel}>
                <img src="" alt="" />
                <img
                  src={CancelIcon}
                  alt="cancel"
                  onTouchStart={() => {
                    setVisible(false)
                  }}
                />
              </div>
              <ul className={styles.menu}>
                {LinkData.map(item => (
                  <li key={item.name}>
                    <Link
                      to={item.to}
                      style={{
                        color: "#183B56",
                        fontSize: "15px",
                        width: "100%",
                      }}
                      onClick={() => {
                        setTimeout(() => {
                          setVisible(false)
                        }, 100)
                      }}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </Col>
      </Row>
    </div>
  )
}
