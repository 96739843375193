// extracted by mini-css-extract-plugin
export var copyright = "index-module--copyright--3873c";
export var first = "index-module--first--73333";
export var footer = "index-module--footer--8eaa0";
export var footer_main = "index-module--footer_main--fc5bd";
export var left = "index-module--left--66538";
export var line = "index-module--line--03eac";
export var list = "index-module--list--51445";
export var mail = "index-module--mail--872ba";
export var mobile = "index-module--mobile--11d7d";
export var right = "index-module--right--dbc35";
export var title = "index-module--title--8e911";